import React from 'react';
import MenuHome from '../components/MenuHome';
import '../styles/main.scss';

const Physics = () => {
  return <div>
        <div className="title">Physics</div>
        <div class="caption">The nature and properties of matter and energy.</div>
        <MenuHome />
  </div>;
};

export default Physics;