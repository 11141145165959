import React from 'react';
import MenuHome from '../../components/MenuHome';
import MenuComputerScience from '../../components/MenuComputerScience';
import '../../styles/main.scss';

const DataStructures = () => {
  return <div>
        <div className="title">Data Structures</div>
        <div class="caption"></div>
        <MenuHome />
        <MenuComputerScience />
  </div>;
};

export default DataStructures;