import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/main.scss';

const MenuHome = () => {
  // Get the current URL using the useLocation hook
  const location = useLocation();

  // Define an array of nav items with their respective URLs
  const navItems = [
    { name: 'Home', url: '/computer-science/programming' },
    { name: 'C', url: '/computer-science/programming/c' },
    { name: 'C++', url: '/computer-science/programming/cpp' },
    { name: 'C#', url: '/computer-science/programming/csharp' },
    { name: 'CSS', url: '/computer-science/programming/css' },
    { name: 'HTML', url: '/computer-science/programming/html' },
    { name: 'Java', url: '/computer-science/programming/java' },
    { name: 'JavaScript', url: '/computer-science/programming/javascript' },  
    { name: 'Python', url: '/computer-science/programming/python' },
    { name: 'R', url: '/computer-science/programming/r' },
    { name: 'React', url: '/computer-science/programming/react' },
  ];

  return (
    <div>
      <nav>
        <ul>
          {/* Map over the navItems array and render each nav item */}
          {navItems.map((item, index) => (
            <li key={index}>
              {/* Check if the current URL matches the item's URL exactly or if the current URL starts with the item's URL */}
              {(location.pathname === item.url && item.url === '/computer-science/programming') || (location.pathname === item.url && item.url !== '/computer-science/programming') ? (
                <span style={{ fontWeight: 'bold' }}>{item.name}</span>
              ) : (
                <a href={item.url}>{item.name}</a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MenuHome;
