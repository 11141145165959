import React from 'react';
import MenuHome from '../components/MenuHome';
import MenuComputerScience from '../components/MenuComputerScience';
import '../styles/main.scss';

const ComputerScience = () => {
  return <div>
        <div className="title">Computer Science</div>
        <div class="caption"></div>
        <MenuHome />
        <MenuComputerScience />
  </div>;
};

export default ComputerScience;