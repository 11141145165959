import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/main.scss';

const MenuHome = () => {
  // Get the current URL using the useLocation hook
  const location = useLocation();

  // Define an array of nav items with their respective URLs
  const navItems = [
    { name: 'Home', url: '/' },
    { name: 'Computer Science', url: '/computer-science' },
    { name: 'Ham Radio', url: '/ham-radio' },
    { name: 'Mathematics', url: '/mathematics' },
    { name: 'Physics', url: '/physics' },
    { name: 'Robotics', url: '/robotics' },
  ];

  return (
    <div>
      <nav>
        <ul>
          {/* Map over the navItems array and render each nav item */}
          {navItems.map((item, index) => (
            <li key={index}>
              {/* Check if the current URL matches the item's URL exactly or if the current URL contains the item's URL and it's not the Home page */}
              {location.pathname === item.url || (location.pathname.startsWith(item.url) && item.url !== '/') ? (
                <span style={{ fontWeight: 'bold' }}>{item.name}</span>
              ) : (
                <a href={item.url}>{item.name}</a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MenuHome;
