import React from 'react';
import MenuHome from '../../../components/MenuHome';
import MenuComputerScience from '../../../components/MenuComputerScience';
import MenuProgramming from '../../../components/MenuProgramming';
import '../../../styles/main.scss';

const HTML = () => {
  return <div>
        <div className="title">HTML</div>
        <div class="caption">Hypertext Markup Language</div>
        <MenuHome />
        <MenuComputerScience />
        <MenuProgramming />
  </div>;
};

export default HTML;