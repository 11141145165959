import React from 'react';
import MenuHome from '../components/MenuHome';
import '../styles/main.scss';

const Mathematics = () => {
  return <div>
        <div className="title">Mathematics</div>
        <div class="caption">The abstract science of number, quantity, and space.</div>
        <MenuHome />
  </div>;
};

export default Mathematics;