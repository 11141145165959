import React from 'react';
import '../styles/main.scss';
import MenuHome from '../components/MenuHome';

const Home = () => {
    return (
        <div>
            <div className="title">Trenton's Memory</div>
            <div className="caption">The things Trenton should know!</div>
            <MenuHome />
        </div>
    );
};

export default Home;