import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import HamRadio from './pages/HamRadio';
import Mathematics from './pages/Mathematics';
import Physics from './pages/Physics';
import ComputerScience from './pages/ComputerScience';
import Programming from './pages/ComputerScience/Programming';
import C from './pages/ComputerScience/Programming/C';
import Cpp from './pages/ComputerScience/Programming/Cpp';
import CSharp from './pages/ComputerScience/Programming/CSharp';
import CSS from './pages/ComputerScience/Programming/CSS';
import HTML from './pages/ComputerScience/Programming/HTML';
import Java from './pages/ComputerScience/Programming/Java';
import JavaScript from './pages/ComputerScience/Programming/JavaScript';
import Perl from './pages/ComputerScience/Programming/Perl';
import PHP from './pages/ComputerScience/Programming/PHP';
import Python from './pages/ComputerScience/Programming/Python';
import R from './pages/ComputerScience/Programming/R';
import ReactJS from './pages/ComputerScience/Programming/ReactJS';
import Robotics from './pages/Robotics';
import Algorithms from './pages/ComputerScience/Algorithms';
import DataStructures from './pages/ComputerScience/DataStructures';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/computer-science" element={<ComputerScience />} />
        <Route path="/computer-science/algorithms" element={<Algorithms />} />
        <Route path="/computer-science/data-structures" element={<DataStructures />} />
        <Route path="/computer-science/programming" element={<Programming />} />
        <Route path="/computer-science/programming/c" element={<C />} />
        <Route path="/computer-science/programming/cpp" element={<Cpp />} />
        <Route path="/computer-science/programming/csharp" element={<CSharp />} />
        <Route path="/computer-science/programming/css" element={<CSS />} />
        <Route path="/computer-science/programming/html" element={<HTML />} />
        <Route path="/computer-science/programming/java" element={<Java />} />
        <Route path="/computer-science/programming/javascript" element={<JavaScript />} />
        <Route path="/computer-science/programming/perl" element={<Perl />} />
        <Route path="/computer-science/programming/php" element={<PHP />} />
        <Route path="/computer-science/programming/python" element={<Python />} />
        <Route path="/computer-science/programming/r" element={<R />} />
        <Route path="/computer-science/programming/react" element={<ReactJS />} />
        <Route path="/ham-radio" element={<HamRadio />} />
        <Route path="/mathematics" element={<Mathematics />} />
        <Route path="/physics" element={<Physics />} /> 
        <Route path="/robotics" element={<Robotics />} />    
      </Routes>
    </BrowserRouter>
  );
}

export default App;