import React from 'react';
import MenuHome from '../components/MenuHome';
import '../styles/main.scss';

const Robotics = () => {
  return <div>
        <div className="title">Robotics</div>
        <div class="caption"></div>
        <MenuHome />
  </div>;
};

export default Robotics;