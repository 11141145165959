import React from 'react';
import MenuHome from '../../../components/MenuHome';
import MenuComputerScience from '../../../components/MenuComputerScience';
import MenuProgramming from '../../../components/MenuProgramming';
import '../../../styles/main.scss';

const PHP = () => {
  return <div>
        <div className="title">PHP</div>
        <div class="caption"></div>
        <MenuHome />
        <MenuComputerScience />
        <MenuProgramming />
  </div>;
};

export default PHP;