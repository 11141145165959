import React from 'react';
import MenuHome from '../../components/MenuHome';
import MenuComputerScience from '../../components/MenuComputerScience';
import MenuProgramming from '../../components/MenuProgramming';
import '../../styles/main.scss';

const Programming = () => {
  return <div>
        <div className="title">Programming</div>
        <div class="caption"></div>
        <MenuHome />
        <MenuComputerScience />
        <MenuProgramming />
  </div>;
};

export default Programming;