import React from 'react';
import MenuHome from '../components/MenuHome';
import '../styles/main.scss';

const HamRadio = () => {
  return <div>
        <div className="title">Ham Radio</div>
        <div class="caption">W5TWS</div>
        <MenuHome />
  </div>;
};

export default HamRadio;